<template>
    <div v-loading.fullscreen.lock="loading" class="mra-login">
        <the-header></the-header>
        <div class="content-area" style="padding: 65px 5px">
            <div class="container-fluid">
                <h5 class="mb-4">
                    If you are a current member of the MRA, you will receive special pricing by clicking the box next to the “Already a member of the MRA” and enter your member ID to receive your discount. Next, to get an estimate for one of the packages or courses, enter the required information and click the check box next to the name of the package(s) or course(s) you are interested in. Once your selection is complete, click the button that says “Click here for estimate.” If you have any questions or don’t see a course you need, please contact us at
                    <a :href="'mailto:' + infoEmail" class="linkColor">{{ infoEmail }}</a
                    >.
                </h5>
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-7 form-section">
                        <validation-observer ref="formValidator" v-slot="{ handleSubmit }">
                            <div v-if="showCompanyinformation">
                                <form role="form" @submit.prevent="handleSubmit(showAgreement)">
                                    <div class="form-row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">Company Type </label><br/>
                                            <el-select v-model="company.company_type" class="mr-3" placeholder="Select Company Type" rules="required" style="width: 100%">
                                                <el-option v-for="(option, index) in company_types" :key="'company_type_' + index" :label="option.label" :value="option.value" class="select-primary"></el-option>
                                            </el-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Company Name <span class="req"> *</span></label>
                                            <base-input v-model="company.name" name="Company name" placeholder="Company Name" rules="required" type="text"></base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <base-input v-model="company.website" label="Website" name="Website" placeholder="Website" type="text"></base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Company Logo</label>
                                            <form>
                                                <file-input v-on:change="onImageChange"></file-input>
                                            </form>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">Address <span class="req"> *</span></label>
                                            <base-input v-model="company.address_1" name="Address" placeholder="Address" rules="required" type="text"></base-input>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">City <span class="req"> *</span></label>
                                            <base-input v-model="company.city" name="City" placeholder="city" rules="required" type="text"></base-input>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">State <span class="req"> *</span></label>
                                            <base-input v-model="company.state" name="State" placeholder="State" rules="required" type="text"></base-input>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">Zip code <span class="req"> *</span></label>
                                            <base-input v-model="company.zip" name="Zip code" placeholder="Zip" rules="required" type="number"></base-input>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-12"><h3 class="mt-4 ml-2" style="color: #444c57">Administrator Login Information</h3> <hr/></div>
                                    </div>

                                    <div class="form-row">
                                        <div class="col-md-4">
                                            <label class="form-control-label">First Name <span class="req"> *</span></label>
                                            <base-input v-model="company.first_name" name="First Name" placeholder="First Name" rules="required" type="text"></base-input>
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                            <base-input v-model="company.last_name" name="Last Name" placeholder="Last Name" rules="required" type="text"></base-input>
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Phone <span class="req"> *</span></label>
                                            <base-input v-model="company.telephone_no" name="Phone Number" placeholder="Phone" rules="required" @input="acceptNumber"></base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Email Address <span class="req"> *</span></label>
                                            <base-input v-model="company.email" name="Email Address" placeholder="Email Address" rules="required" type="email"></base-input>
                                        </div>

                                        <div class="col-md-5">
                                            <label class="form-control-label">Password <span class="req"> *</span></label>
                                            <base-input v-if="!(company_id !== '')" v-model="company.password" :type="passwordFieldType" name="Password" placeholder="Password" rules="required"></base-input>
                                            <base-input v-if="company_id !== ''" v-model="company.password" :type="passwordFieldType" name="Password" placeholder="Password"></base-input>
                                        </div>
                                        <div class="col-md-1 password-eye" style="margin-top: 40px">
                                            <span @click.prevent="switchVisibility"><i class="fa fa-eye" title="Show Password"></i></span>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <h3>AUTHORIZATION</h3>
                                        <p>
                                            <small> By clicking the "Create Account" button below, you agree that the name you typed in the box above (Your Full Name) will be the electronic representation of your signature for all purposes in relation to the Train 321, LLC legally binding Service Activation Agreement. You agree that your electronic signature is considered an original, for purposes of entering into a contract. </small>
                                        </p>
                                    </div>
                                    <div class="text-center">
                                        <button class="btn btn-primary mt-4" type="submit">Continue</button>
                                    </div>
                                </form>
                            </div>
                            <div v-else class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">First Name <span class="req"> *</span></label>
                                            <base-input v-model="company.first_name" name="First Name" placeholder="First Name" rules="required" type="text"></base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                            <base-input v-model="company.last_name" name="Last Name" placeholder="Last Name" rules="required" type="text"></base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Phone <span class="req"> *</span></label>
                                            <base-input v-model="company.telephone_no" name="Phone Number" placeholder="Phone" rules="required" @input="acceptNumber"></base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Email Address <span class="req"> *</span></label>
                                            <base-input v-model="company.email" name="Email Address" placeholder="Email Address" rules="required" type="email"></base-input>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="form-control-label">Company Name <span class="req"> *</span></label>
                                            <base-input v-model="company.name" name="Company name" placeholder="Company Name" rules="required" type="text"></base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label"># of Locations <span class="req"> *</span></label>
                                            <base-input v-model="company.no_of_locations" min="1" name="Location" rules="required" type="number"></base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Estimated # of Users <span class="req"> *</span></label>
                                            <base-input v-model="company.no_of_employees" min="1" name="Users " rules="required" type="number"></base-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" style="font-style: italic">
                                    <h5 class="reduceFont"><span class="text-danger">*</span>Indicates a required field. The estimated pricing is based upon the number of locations, users and courses selected for your company.</h5>
                                </div>
                            </div>
                        </validation-observer>
                    </div>
                    <div class="col-md-12 col-lg-12 col-xl-5">
                        <div class="row">
                            <div class="col-md-12">
                                <base-checkbox v-model="already_member"><b>Already a member of the MRA? (Enter your Member ID to receive a discount).</b></base-checkbox>
                            </div>
                            <div class="col-md-11 mt-1" style="margin-left: 5%">
                                <h5>
                                    Not a member,
                                    <a class="linkColor" href="https://www.themassrest.org/why-join-the-mra.html"> click here </a>
                                    to join.
                                </h5>
                            </div>

<!--                            <div v-if="already_member" class="col-md-12 mt-1">-->
<!--                                <label class="form-control-label">Member ID:</label>-->
<!--                                <input id="memberId" v-model="member_id" :style="inputStyle" class="form-control" placeholder="Provide member ID" type="text"/>-->
<!--                            </div>-->
                            <div class="col-md-12 mt-2">
                                <!-- start here -->

                                <el-checkbox-group v-model="checked_coursefolders">
                                    <span v-for="option in coursefolders" :key="option.id">
                                        <el-popover :id="option.id" :key="option.id" :ref="`fromPopOver${option.id}`" placement="top-start" trigger="hover" width="500">
                                            <div class="provideScroller">
                                                <ul>
                                                    <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                        {{ courses.name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-popover>
                                        <el-checkbox :label="option.id" false-label="No" true-label="Yes" @change="courseFolderChecked">
                                            {{ option.coursefolder_name }}
                                        </el-checkbox>
                                        <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span><br/>
                                    </span>
                                </el-checkbox-group>

                                <!-- end here -->
                                <el-checkbox-group v-model="checked_courses">
                                    <span v-for="option in course" :key="option.id">
                                        <el-checkbox v-if="option.course_type == 0" :label="option.id" @change="coursesFolder(option.id)">{{ option.course_name }}</el-checkbox
                                        ><br/>
                                    </span>
                                </el-checkbox-group>
                                <el-checkbox-group v-model="checked_other_courses">
                                    <span v-for="option in basicPackage1" :key="option.id">
                                        <el-popover :id="option.id" :key="option.id" :ref="`fromPopOver${option.id}`" placement="top-start" trigger="hover" width="500">
                                            <div class="provideScroller">
                                                <ul>
                                                    <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                        {{ courses.name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-popover>
                                        <el-checkbox :label="option.id">{{ option.coursefolder_name }}</el-checkbox>
                                        <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                    </span>
                                </el-checkbox-group>

                                <el-checkbox-group v-model="checked_other_courses">
                                    <span v-for="option in basicPackage2" :key="option.id">
                                        <el-popover :id="option.id" :key="option.id" :ref="`fromPopOver${option.id}`" placement="top-start" trigger="hover" width="500">
                                            <div class="provideScroller">
                                                <ul>
                                                    <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                        {{ courses.name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-popover>
                                        <el-checkbox v-if="checked_coursefolders.length > 0" :label="option.id" disabled>{{ option.coursefolder_name }}</el-checkbox>
                                        <el-checkbox v-else-if="checked_coursefolders.length == 0" :label="option.id">{{ option.coursefolder_name }}</el-checkbox
                                        ><span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span><br/>
                                    </span>
                                </el-checkbox-group>
                            </div>
                        </div>

                        <div v-if="!showCompanyinformation && (checked_courses.length || checked_coursefolders.length || checked_other_courses.length) && !lead_id" class="text-right">
                            <base-button class="mt-2" @click.prevent="checkIfMRAMember">Click Here for Estimate</base-button>
                        </div>
                        <div v-else class="text-right">
                            <base-button v-if="!showPricePlan" class="basebutton mt-2" disabled>Click Here for Estimate</base-button>
                        </div>

                        <div v-if="showPricePlan && lead_id" class="price-area">
                            <hr/>

                            <div class="row">
                                <div class="col-md-12" style="color: darkblue">
                                    <div class="row">
                                        <div class="col-md-9 col-8">
                                            <small style="text-decoration: underline"
                                            ><b>Total Cost Per Month: <span class="req">*</span></b>
                                            </small>
                                        </div>
                                        <div class="col-md-3 col-4">
                                            <small style="text-decoration: underline"
                                            ><b>{{ formatPrice(total_cost) }}</b></small
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12" style="color: darkblue">
                                    <div class="row">
                                        <div class="col-md-9 col-8">
                                            <small
                                            >Price if paid in full for the year:
                                            </small>
                                            <hr style="margin-top: -9px !important; margin-bottom: 4px !important; margin-left: 250px"/>
                                        </div>
                                        <div class="col-md-3 col-4">
                                            <small>{{ formatPrice(perYearCost) }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                        </div>
                        <div>
                            <base-button v-if="showCompanyinformation || lead_id" type="danger" @click.prevent="submitLead">Re-estimate</base-button>
                            <base-button v-if="!showCompanyinformation && lead_id" style="margin-left: 20px" type="success" @click.prevent="companyDetails()">Continue Sign Up</base-button>
                        </div>
                        <div class="mt-3">
                            <span v-if="!onlySexualCourse"
                            ><h5><span class="req">*</span>Monthly prices are based upon a 1-year agreement that will automatically renew each year.</h5></span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal :show.sync="agreementModal" class="user-modal">
            <h3 slot="header">Service Activation Agreement</h3>
            <form>
                <div class="agreement-content">
                    <agreement type="company"></agreement>
                </div>
                <div class="text-center mt-2">
                    <base-button type="success" @click.prevent="finalCreateAccount"> I Agree</base-button>
                    <base-button type="danger" @click.prevent="cancelAgreement"> Cancel</base-button>
                </div>
            </form>
        </modal>
        <modal :show.sync="showPaymentOption">
            <h4 slot="header" class="title title-up text-center" style="color: #444c57">Payment</h4>
            <mra-credit-card v-if="showPaymentOption" :address="company.address_1" :city="company.city" :is-mra-signup="true" :isMraRegister="true" :monthlyAmount="total_cost" :onlySexualCourse="onlySexualCourse" :state="company.state" :yearlyAmount="perYearCost" :zip="company.zip" type="company" v-on:payClicked="payClicked"/>
        </modal>
        <the-footer/>
    </div>
</template>

<script>
import FileInput from "@/components/Inputs/FileInput";
import {Checkbox, CheckboxGroup, Option, OptionGroup, Select, Table, TableColumn} from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import MraCreditCard from "@/mra/MRACreditCard";
import Agreement from "@/views/Pages/Agreement.vue";
import TheHeader from "@/mra/partials/Header.vue";
import TheFooter from '@/mra/partials/Footer.vue';

export default {
    name: "register",
    components: {
        Agreement,
        FileInput,
        MraCreditCard,
        [Select.name]: Select,
        [Option.name]: Option,
        [OptionGroup.name]: OptionGroup,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        TheHeader,
        TheFooter,
    },
    data() {
        return {
            users: 0,
            already_member: false,
            member_id: "",
            courseSelectionFocused: false,
            courseFolderSelectionFocused: false,
            focused: "blue-theme",
            formData: {
                company_name: "",
                first_name: "",
                last_name: "",
                company_location_num: "",
                company_employee_num: "",
                company_address_1: "",
                company_address_2: "",
                company_phone: "",
                company_email: "",
                company_zip: "",
                website: "",
                company_type: "",
                username: "",
                parent_id: "",
                image: "",
                company_city: "",
                company_state: "",
                company_password: "",
            },
            baseUrl: this.$baseUrl,
            hot_user: "",
            hot_token: "",
            config: "",
            company_id: "",
            creatAccountClicked: false,
            company_types: [],
            image: "",
            sub_total: "",
            special_courses: [],
            company: {
                first_name: "",
                last_name: "",
                company_type: "",
                parent_company: "",
                name: "",
                administrator: "",
                no_of_locations: "",
                no_of_employees: "",
                address_1: "",
                address_2: "",
                city: "",
                state: "",
                zip: "",
                logo: "",
                telephone_no: "",
                email: "",
                password: "",
            },

            search: "",
            contactUsModal: false,
            agreementModal: false,
            passwordFieldType: "password",
            courses: [],
            coursefolders: [],
            basicPackage1: [],
            basicPackage2: [],
            services: [],
            contact: {
                name: "",
                phone: "",
                email: "",
                message: "",
            },
            siteName: "",
            infoEmail: "",
            lead_id: "",
            checked_courses: [],
            checked_other_courses: [],
            checked_coursefolders: [],
            companyEstimateDetailModel: false,
            showCompanyinformation: false,
            showPricePlan: false,
            total_cost: "",
            total_discount: "",
            perYearCost: "",
            employees_count: "",
            locations_count: "",
            course: "",
            role: "",
            showPaymentOption: false,
            onlySexualCourse: 1,
            inputStyle: "",
            loading: false,
            is_coursefolderSelected: false,
            isCourseSelected: true,
            isSexualHarrasment: 0,
        };
    },
    mounted() {
        this.siteName = "MRA";
        this.infoEmail = "mra@train321.com";
        if (this.$route.query.inner) {
            this.$gtag.event("S.H.P. Company signup", {method: "Google"});
        } else if (this.$route.query.course_type) {
            this.$gtag.event("Sexual Harassment Prevention Company signup", {
                method: "Google",
            });
        } else {
            this.$gtag.event("Company signup", {method: "Google"});
        }
    },
    async created() {
        this.loading = true;
        localStorage.removeItem("fname");
        localStorage.removeItem("lname");
        localStorage.removeItem("email");
        localStorage.removeItem("courses");
        if (this.$route.query.role) {
            this.role = this.$route.query.role;
        }

        this.$http.post("company/company_dropdown_data", {}).then((resp) => {
            for (let type of resp.data.companytype) {
                let obj = {
                    label: type.type,
                    value: type.id,
                };
                this.company_types.push(obj);
            }
        });
        await this.$http.get("mra/discountRules").then((resp) => {
            for (let course of resp.data.courses) {
                if (course.id == 100 || course.id == 103 || course.id == 104) {
                    course.course_type = 0;
                }

                if (course.course_type == 0) {
                    let obj = {
                        checked: false,
                        id: course.id,
                        course_name: course.name,
                        course_type: course.course_type,
                        cost: course.cost,
                    };
                    this.courses.push(obj);
                }
            }
            this.course = this.courses;
            for (let coursefolder of resp.data.course_folders) {
                if (coursefolder.id == 5 || coursefolder.id == 4) {
                    coursefolder.folder_type = 1;
                }

                if (coursefolder.folder_type == 1) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        attachedCourses: coursefolder.active_courses,
                    };

                    this.coursefolders.push(obj1);
                }
                if (coursefolder.folder_type == 2) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        attachedCourses: coursefolder.active_courses,
                    };

                    this.basicPackage1.push(obj1);
                }
                if (coursefolder.folder_type == 3) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        attachedCourses: coursefolder.active_courses,
                    };

                    this.basicPackage2.push(obj1);
                }
            }
            this.loading = false;
        });
        if (this.$route.query.course_type) {
            this.isSexualHarrasment = 1;
            this.checkdefaultSexualCourse();
        }
    },
    methods: {
        courseFolderChecked(value) {
            this.is_coursefolderSelected = !this.is_coursefolderSelected;
        },
        coursesFolder(value) {
            this.isCourseSelected = !this.isCourseSelected;
        },
        /*courseFolderChecked(value) {
            if (value == "Yes") {
                this.checked_coursefolders = [];
                this.coursefolders.forEach((chck) => {
                    this.checked_coursefolders.push(chck.id);
                });
                this.courses.forEach((chck) => {
                    if (chck.course_type == 0) {
                        this.checked_courses.push(chck.id);
                    }
                });
                this.basicPackage2.forEach((chck) => {
                    this.checked_other_courses.pop(chck.id);
                });
            } else {
                this.checked_coursefolders = [];
                this.checked_courses = [];
            }
        },*/
        checkdefaultSexualCourse() {
            console.clear();
            console.warn(this.courses);
            this.courses.forEach((chck) => {
                if (chck.course_type == 0) {
                    this.checked_courses.push(chck.id);
                }
            });
        },
        showDone() {
            this.courseSelectionFocused = true;
        },
        doneClicked(e) {
            this.courseSelectionFocused = false;
        },
        showDoneButton() {
            this.courseFolderSelectionFocused = true;
        },
        doneButtonClicked(e) {
            this.courseFolderSelectionFocused = false;
        },
        cancelAgreement() {
            this.agreementModal = false;
        },
        showContactUs() {
            this.contactUsModal = true;
        },
        finalCreateAccount() {
            //this.createAccount(this.formData);
            this.agreementModal = false;
            this.showPaymentOption = true;
        },
        payClicked(cardData) {
            this.loading = true;
            let payment = {
                payment_type: "",
                cardholder_street_address: cardData.address + "," + cardData.city + "," + cardData.state,
                cardholder_zip: cardData.zip,
                transaction_amount: "",
                token: cardData.token,
            };
            if (this.onlySexualCourse) {
                payment.payment_type = "one-time";
            } else {
                payment.payment_type = cardData.paymentType;
            }
            if (cardData.paymentType == "monthly") {
                payment.transaction_amount = this.total_cost.toFixed(2);
            }
            if (cardData.paymentType == "yearly") {
                payment.transaction_amount = this.perYearCost.toFixed(2);
            }
            this.formData.payment = payment;
            this.formData.address_1 = cardData.address;
            this.formData.company_address_1 = cardData.address;
            this.formData.company_state = cardData.state;
            this.formData.company_city = cardData.city;
            this.formData.company_zip = cardData.zip;
            this.createAccount(this.formData);
        },

        showAgreement() {
            this.formData = {
                company_name: this.company.name,
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                company_location_num: this.company.no_of_locations,
                company_employee_num: this.company.no_of_employees,
                company_address_1: this.company.address_1,
                company_address_2: this.company.address_2,
                company_phone: this.company.telephone_no,
                company_email: this.company.email,
                company_zip: this.company.zip,
                website: this.company.website,
                company_type: this.company.company_type,
                username: this.company.email,
                parent_id: this.company.parent_company,
                image: this.image,
                company_city: this.company.city,
                company_state: this.company.state,
                company_password: this.company.password,
                course_ids: this.checked_courses,
                courseFolders: this.checked_coursefolders,
                otherCourseFolders: this.checked_other_courses,
                already_member: this.already_member,
                member_id: this.member_id,
                status: 1,
                payment: [],
                card_info: [],
                i_agree: true,
            };
            this.agreementModal = true;
        },

        formatPrice(value) {
            return "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },
        checkIfMRAMember() {
            if (!this.already_member) {
                Swal.fire({
                    title: "Already a MRA Member?",
                    html: '<a target="__blank" href="https://www.themassrest.org/why-join-the-mra.html"> Click here</a> to join.',
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success btn-fill",
                    cancelButtonClass: "btn btn-danger btn-fill",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    buttonsStyling: false,
                    icon: "question",
                }).then((result) => {
                    if (result.value) {
                        this.inputStyle = "border:2px solid #f8981c;";
                        this.already_member = true;
                    } else {
                        this.submitLead();
                    }
                });
            } else {
                this.submitLead();
            }
        },
        submitLead() {
            if (!this.company.first_name || !this.company.telephone_no || !this.company.email || !this.company.name || !this.company.no_of_locations || !this.company.no_of_employees || this.company.no_of_employees == 0 || this.company.no_of_locations == 0) {
                return Swal.fire({
                    title: "Error!",
                    text: "Please fill all mandatory fields.",
                    icon: "error"
                });
            }

            if (this.company.no_of_employees == "" || this.company.no_of_employees >= 201) {
                return Swal.fire({
                    title: "Attention!",
                    html: "Please contact our sales team at <a href='mailto:MRA@train321.com'>MRA@train321.com</a> for special pricing.",
                    icon: "warning",
                    confirmButtonClass: "btn btn-success btn-fill",
                    confirmButtonText: "OK",
                });
            }
            let data = {
                company_name: this.company.name,
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                number_of_locations: this.company.no_of_locations,
                number_of_employees: this.company.no_of_employees,
                email: this.company.email,
                phone_num: this.company.telephone_no,
                user_type: "corporate",
                already_member: this.already_member,
                member_id: this.member_id,
                course_ids: this.checked_courses,
                course_folders: this.checked_coursefolders,
                other_courses: this.checked_other_courses,
                isSexualHarrasment: this.isSexualHarrasment,
            };
            this.$http
                .post("mra/lead", data)
                .then((resp) => {
                    this.lead_id = resp.data.user_id;
                    this.companyEstimateDetailModel = false;
                    this.total_cost = resp.data.perYearCost;
                    this.sub_total = resp.data.perYearCost;
                    this.employees_count = resp.data.number_of_employees;
                    this.locations_count = resp.data.number_of_locations;
                    this.showPricePlan = true;
                    this.perYearCost = resp.data.sub_total;
                    this.yearDiscount = resp.data.yearDiscount;
                    this.discount = resp.data.discount;
                    this.onlySexualCourse = resp.data.only_sexual_course;
                })
                .catch(function (error) {
                    if (error.response.status === 422) {
                        return Swal.fire({
                            title: "Error!",
                            html: error.response.data.message,
                            icon: "error",
                        });
                    }
                });
        },
        companyDetails() {
            this.showCompanyinformation = true;
        },
        companyEstimateDetail() {
            this.companyEstimateDetailModel = true;
        },
        hideModel() {
            this.companyEstimateDetailModel = false;
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        cancelContact() {
            this.contactUsModal = false;
        },
        saveContact() {
            let data = {
                name: this.contact.name,
                email: this.contact.email,
                phone: this.contact.phone,
                message: this.contact.message,
            };
            this.$http
                .post("user/contact", data)
                .then((resp) => {
                    this.contactUsModal = false;
                    Swal.fire({
                        title: "Success!",
                        text: resp.data.message,
                        icon: "success",
                    });
                })
                .catch(function (error) {
                    if (error.response.status === 422) {
                        let respmessage = error.response.data.message.replace(/,/g, "\n");
                        Swal.fire({
                            title: "Error!",
                            text: respmessage,
                            icon: "error",
                        });
                    }
                });
        },

        acceptNumber() {
            var x = this.company.telephone_no.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.company.telephone_no = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

            var y = this.contact.phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.contact.phone = !y[2] ? y[1] : "(" + y[1] + ") " + y[2] + (y[3] ? "-" + y[3] : "");
        },
        onImageChange(e) {
            let files = e;
            if (!files.length) return;
            this.createImage(files[0]);
        },
        createImage(file) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        createAccount(formDataSubmitted) {
            delete this.$http.defaults.headers["authorization"];
            this.creatAccountClicked = true;
            this.$http
                .post("mra/register", formDataSubmitted)
                .then((resp) => {
                    this.loading = false;
                    let ids = [];
                    let obj = {
                        id: resp.data.id,
                    };
                    ids.push(obj);
                    this.showPaymentOption = false;
                    this.$http
                        .post("company/welcome_email", {
                            form_data: formDataSubmitted,
                            password: this.company.password,
                            ids: ids,
                        })
                        .then((resp) => {
                            this.$router.push("/mra-login");
                            this.agreementModal = false;
                            Swal.fire({
                                title: "Success!",
                                text: `Account created successfully.`,
                                icon: "success",
                            });
                        })
                        .catch(function (error) {
                            this.$router.push("/mra-login");
                            return Swal.fire({
                                title: "Success!",
                                text: "Account created successfully.",
                                icon: "success",
                            });
                        });
                })
                .catch(function (error) {
                    if (error.response.status === 422) {
                        return Swal.fire({
                            title: "Error!",
                            text: error.response.data.message,
                            icon: "error",
                        });
                    }
                });
        },
    },
    watch: {
        is_coursefolderSelected: function () {
            this.checked_coursefolders = [];
            if (this.is_coursefolderSelected) {
                this.coursefolders.forEach(courseFolder => {
                    this.checked_coursefolders.push(courseFolder.id);
                })
            }
        },
        isCourseSelected: function () {
            this.checked_courses = [];
            if (this.isCourseSelected) {
                this.checkdefaultSexualCourse();
            }
        }
    }
};
</script>
<style scoped>
.ctm-btn {
    background-color: #ef8f1d;
    color: #ffffff;
    font-size: 17px;
    margin-right: 5px;
}

.login-inputs {
    padding-top: 20px;
}

.login {
    margin-right: 0px !important;
}

.training-card {
    box-shadow: 1px 1px 5px rgb(0 0 0 / 30%);
    transition: all 0.22s;
    transform: translateZ(0);
}

.training-content {
    position: relative;
}

.training-content {
    padding: 20px;
    min-height: 160px;
}

.training-content h3 {
    color: #284a5b;
    font-size: 24px;
    margin-bottom: 20px;
}

#footer-main {
    height: 60px;
    padding: 10px 0px;
}

footer {
    background-color: #f3f3f3;
    padding: 15px 0;
    width: 100%;
    bottom: 0;
}

.footer-link {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
}

.footer-link li:not(:last-child) {
    margin-right: 15px;
}

.footer-link li a {
    color: #8a8686;
    font-size: 14px;
}

.social-link {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.social-link li:not(:last-child) {
    margin-right: 10px;
}

.knowMore-info {
    font-size: 13px;
    margin-left: 6px;
    text-decoration: underline;
    font-style: italic;
    color: #5e72e4 !important;
    cursor: pointer;
}
</style>
